@import "../../styles/Variables/Variables.scss";

#addform{
    margin-top: 30px;
    padding: 30px;
    border-radius: 10px;

        #addheading{
            font-size: 16px;
            font-family: $sffont-medium;
            margin-bottom: 20px;
        }

        .selectdate{
            padding: 5px;
            border: 1px solid #49154a;
            border-radius: 5px;
        }
}