@import "/src/styles/Variables/Variables.scss";

.upload_btn{
    display: inline-block;
    border: 2px solid #60677e;
    color: #60677e;
    padding: 5px 10px;
    font-family:$sffont-regular;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }

.uploaded_imgsection{
    width: 100px;
    height: 150px;
}

#uploaded_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imgcancel_btn{
    border: none;
    font-family:$sffont-regular;
    font-size: 14px;
    padding: 5px 30px;
    background-color: #e2e3e7;
    margin-top: 5px;
    border-radius: 5px;

     &:hover{
        background-color: #a9a9a9;
     }
}