@import "../../styles/Variables/Variables.scss";

.indexdiv{

    #empheading{
        font-size: 18px;
        font-family: $sffont-medium;
        padding-bottom: 50px;
    }
    .exportsection{
        width: 100%;
        margin-top: 25px;
    }
}

.replyy{
    border: 1px solid #423f3f;
    color: #212121;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    font-size: 13px;
    font-weight: 600;
  }

@media only screen and (max-width: 768px) {
    .exportsection{
        margin-top: 10px !important;
    }
}

@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}