@import "../../styles/Variables/Variables.scss";


#addform{

    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 30px;
    padding-bottom: 30px;

    #addheading{
        font-size: 16px;
        font-family: $sffont-medium;
        margin-bottom: 20px;
    }

    .inputbox{
        padding: 4px 5px 8px 10px;
        border: none;
        border-radius: 5px;
        width: 100%;
        background-color: #dde2eb63;
        color: $black-color;
        font-size: 14px;
    }
    .inputbox1{
        padding: 4px 5px 8px 10px;
        border: none;
        border-radius: 5px;
        width: 100%;
        background-color: $darkthemecolor;
        color: $lightthemetext;
        font-family: $sffont-regular;
    }
    .formlabel{
        font-family: $sffont-regular;
        font-size: 14px;
    }
    .formbtn{
        background-color: $primary-btncolor;
        color: white;
        border: none;
        width: 100px;
        font-size: 16px;
        padding: 11px;
        border-radius: 5px;

        &:hover{
            background-color: $secondary-btncolor;
        }
    }
    .arrowback{
        float: right;
        background-color: #dde2eb;
        padding: 10px;
        color: #39979d;
        cursor: pointer;
        border-radius: 3px;

          &:hover{
            background-color: #39979d;
            color: #fff;
          }
      }
    
    ::placeholder{
        font-size: 12px;
    }

    
    ul{
        padding-left: 0px;
        display: flex;
          li{
              list-style: none;
              margin-right: 80px;
          }
      } 
  
      .selectlabel{
          margin-left: 5px;
          font-family: $sffont-regular;
          font-size: 16px;
          text-transform: capitalize;
          vertical-align: middle;
      }

    #permissionbox{
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
    }

  }
  

  @media only screen and (max-width: 878px) {
    ul {
        display: list-item !important;
        list-style: none;
    }
  }


  @media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}

