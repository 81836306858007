@import "../../styles/Variables/Variables.scss";

.indexdiv{

    #empheading{
        font-size: 18px;
        font-family: $sffont-medium;
        padding-bottom: 50px;
    }
    .exportsection{
        width: 100%;
        margin-top: 25px;
    }
}

.section_our_solution_row {
    align-items: center;
  }
  
  .our_solution_category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .solution_cards_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .solution_cards_box_solution_card {
    flex: 0 50%;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
      0 5px 15px 0 rgba(37, 44, 97, 0.15);
    border-radius: 15px;
    margin: 8px;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 265px;
    transition: 0.7s;
  }
  
  .solution_cards_box_solution_card:hover {
    background: #309df0;
    color: #fff;
    transform: scale(1.1);
    z-index: 9;
  }
  
  .solution_cards_box_solution_card:hover::before {
    background: rgb(85 108 214 / 10%);
  }
  
  .solution_cards_box_solution_card:hover .solu_title h3,
  .solution_cards_box_solution_card:hover .solu_description p {
    color: #fff;
  }
  
  .solution_cards_box_solution_card:before {
    content: "";
    position: absolute;
    background: rgb(85 108 214 / 5%);
    width: 170px;
    height: 400px;
    z-index: -1;
    transform: rotate(42deg);
    right: -56px;
    top: -23px;
    border-radius: 35px;
  }
  
  .solution_cards_box_solution_card:hover .solu_description_button {
    background: #fff !important;
    color: #309df0;
  }
  
  .solution_card .so_top_icon {
  }
  
  .solu_title_h3 {
    color: #212121;
    font-size: 1.3rem;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  
  .solu_description_p {
    font-size: 15px;
    margin-bottom: 15px;
  }
  
  .solu_description_button {
    border: 0;
    border-radius: 15px;
    background: linear-gradient(
      140deg,
      #42c3ca 0%,
      #42c3ca 50%,
      #42c3cac7 75%
    ) !important;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 16px;
  }
  
  .our_solution_content h1 {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  .our_solution_content p {
  }
  
  .hover_color_bubble {
    position: absolute;
    background: rgb(54 81 207 / 15%);
    width: 100rem;
    height: 100rem;
    left: 0;
    right: 0;
    z-index: -1;
    top: 16rem;
    border-radius: 50%;
    transform: rotate(-36deg);
    left: -18rem;
    transition: 0.7s;
  }
  
  .solution_cards_box .solution_card:hover .hover_color_bubble {
    top: 0rem;
  }
  
  .solution_cards_box .solution_card .so_top_icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .solution_cards_box .solution_card .so_top_icon img {
    width: 40px;
    height: 50px;
    object-fit: contain;
  }

  .actionbtn{
    background: none;  
    border: none;
    margin-right: 10px;
}
  
  /*start media query*/
  @media screen and (min-width: 320px) {
    .sol_card_top_3 {
      position: relative;
      top: 0;
    }
  
    .our_solution_category {
      width: 100%;
      margin: 0 auto;
    }
  
    .our_solution_category .solution_cards_box {
      flex: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    .our_solution_category .solution_cards_box {
      flex: 1;
    }
  }
  @media only screen and (min-width: 1024px) {
    .sol_card_top_3 {
      position: relative;
      top: -3rem;
    }
    .our_solution_category {
      width: 50%;
      margin: 0 auto;
    }
  }
  

@media only screen and (max-width: 768px) {
    .exportsection{
        margin-top: 10px !important;
    }
}

@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}