@import "/src/styles/Variables/Variables.scss";

.activebtn{
   border: none;
   color: $white-color;
   background-color: #50cd89;
   width: 70px;
   border-radius: 34px;
   line-height: 25px;
   font-family: $sffont-regular;
  //  box-shadow: 0 1px 2px #babfc7;
}
.inactivebtn{
  border: none;
  color: #dee2e6;
  background-color: #737a92;
  width: 70px;
  border-radius: 34px;
  line-height: 25px;
  font-family: $sffont-regular;
  // box-shadow: 0 1px 2px #babfc7;
}